<template>
	<div>
		<q-table
			:columns="columns"
			:data="webhooks_reports"
			:loading="pending_results"
			binary-state-sort
		>
			<template v-slot:body-cell-info='item'>
				<q-td>
					<q-btn
						color='dark'
						icon="more_horiz"
						round
						flat
						@click="getAdditionalInfo(item)"
					>
						<q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
							Additional Information
						</q-tooltip>
					</q-btn>
				</q-td>
			</template>
		</q-table>
		<q-dialog v-model="show_additional_info_dialog">
			<additional-info-dialog
				:additional_info="additional_info"
				@close='show_additional_info_dialog = false'
			/>
		</q-dialog>
	</div>
</template>
<script>
import SlackApi from '@/services/api/Slack.js';
import AdditionalInfoDialog from "@/components/integrations/AdditionalInfoDialog.vue";
import moment from 'moment-timezone'
import { mapGetters } from 'vuex';
export default {
	name: 'WebhookSlackReports',
	components: { AdditionalInfoDialog },
	data(){
		return {
			webhooks_reports: [],
			pending_results: false,
			columns: [
				{
					name: 'webhook_name',
					field: row => row.webhook_name ? row.webhook_name : row.deleted_webhook_name + ' (deleted webhook)',
					align: 'left',
					label: 'Webhook name',
					sortable: true,
				},
				{
					name: 'status',
					field: row => row.status,
					align: 'left',
					label: 'Status',
					sortable: true,
				},
				{
					name: 'date_end',
					field: obj => {
						return moment.utc(obj.date_end).tz(this.logged_user.preferred_timezone).format('DD/MM/YYYY HH:mm')
					},
					align: 'left',
					label: 'Date Sent',
					sortable: true,
				},
				{
					name: "info",
					field: "icons",
					label: "Info",
					align: "center"
				}
			],
			show_additional_info_dialog: false,
			additional_info: [],
		}
	},
	computed: {
		...mapGetters(['logged_user']),
	},
	methods:{
		async getWebhooksReports(){
			this.pending_results = true
 			this.webhooks_reports = await SlackApi.getSlackReports()
			this.pending_results = false
		},
		getAdditionalInfo(item){
			this.show_additional_info_dialog = true
			this.additional_info = item.row;
		}
	},
	async created(){
		this.$q.loading.show({
			delay: 400,
		})
		await this.getWebhooksReports()
		this.$q.loading.hide()
	},
}
</script>
